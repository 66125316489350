import React, { FC } from "react";
import { Link } from "react-router-dom";
import styles from "./version.module.css";

interface VersionProps {
    link?: boolean;
}

const Version: FC<VersionProps> = (props) => {
    const version = process.env.REACT_APP_VERSION;
    return props.link !== false ? (
        <Link to="/status" className={styles.version}>
            {version}
        </Link>
    ) : <>{version}</>;
};

export default Version;
