import * as React from "react";
import { ComponentType } from "react";
import { Route } from "react-router";
import { QueryParamProvider } from "use-query-params";

export default function <Props>(WrappedComponent: ComponentType<Props>) {
    const Component: ComponentType<Props> = (props) => (
        <QueryParamProvider ReactRouterRoute={Route}>
            <WrappedComponent {...props}/>
        </QueryParamProvider>
    );

    Component.displayName = `withQueryParamsProvider(${WrappedComponent.displayName || WrappedComponent.name})`;

    return Component;
}
