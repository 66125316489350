import React, { FC } from "react";
import { Spin } from "antd";
import { SpinProps } from "antd/lib/spin";
import style from "./loader.module.css"

export const Loader: FC<SpinProps> = (props) => {
    return (
        <div className={style.loader}>
            <Spin {...props}/>
        </div>
    )
}

export default Loader;
