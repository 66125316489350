import { checkUpdate } from './store/modules/version/versionActions';

const clientFetch: WindowOrWorkerGlobalScope["fetch"] = async (uri, options?: RequestInit) => {
    const store = await import("./").then((m) => m.store);
    const {session} = store.getState();
    const headers = {...(options ? options.headers : {}) as Record<string, string>};
    if (session) {
        headers['X-Authorization'] = `Bearer ${session.token}`;
    }

    const response = await fetch(uri, {
        ...options,
        headers,
    });

    if (
        response.headers.has(versionHeader) &&
        response.headers.get(versionHeader) !== process.env.REACT_APP_VERSION
    ) {
        store.dispatch(checkUpdate(true));
    }

    return response;
};

const versionHeader = 'X-App-Version';

export default clientFetch;
