import { useCallback, useState } from "react";

const useForceRerender = () => {
    const [{forceRerender}, setState] = useState<{forceRerender: number}>({forceRerender: 0});

    return useCallback(() => {
        setState({forceRerender: forceRerender+1});
    }, [setState, forceRerender]);
};

export default useForceRerender;
