export interface ConstraintViolation {
    code: string;
    message: string;
}

const translateViolation = (
    {code, message}: ConstraintViolation,
    overwrites: ViolationMessages = {}
): ConstraintViolation => ({
    code,
    message: overwrites[code] || defaultViolationMessages[code] || message,
});

type ViolationMessages = Readonly<{[code: string]: string}>;

const defaultViolationMessages: ViolationMessages = {
    '07978e40-8ecb-4237-ac21-f447e020ec4a': "Ta wartość jest już zajęta",
    '0a89f710-96bf-4b8b-8e9e-5c5d2931e13c': "Podany adres e-mail istnieje w systemie",
    '84294e58-52e0-4c6e-8e77-107ea2b32abe': "Niepoprawny format numeru REGON",
    'bd79c0ab-ddba-46cc-a703-a7a4b08de310': "Niepoprawny format adresu e-mail",
    'c1051bb4-d103-4f74-8988-acbcafc7fdc3': "Ta wartość nie może być pusta",
    '9ff3fdc4-b214-49db-8718-39c315e33d45': "Podana wartosć jest za krótka",
    'd94b19cc-114f-4f44-9cc4-4138e80a87b9': "Podana wartosć jest za długa",
    'de1e3db3-5ed4-4941-aae4-59f3667cc3a3': "Niepoprawny format",
    '74gdw5sf-25hc-3ead-83d1-3h2b9e154662': "Hasło nie może być takie samo jak adres e-mail",
    '12bfc5dc-10f8-11ea-8d71-362b9e155667': "Hasło nie spełnia naszej polityki haseł. Poprawne hasło musi być długie na co najmniej 8 znaków i zawierać w sobie 1 dużą literę, 1 małą literę, 1 znak specjalny i jedną cyfrę",
    'b4010bf5-a002-4659-a982-a11b2a09c704': "Niepoprawny format NIP",
    '57c2f299-1154-4870-89bb-ef3b1f5ad229': "Niepoprawny format",
    '8e179f1b-97aa-4560-a02f-2a8b42e49df7': "Ta wartość nie jest jedną z poprawnych wartości"
};

export default translateViolation;
