import { gql } from "apollo-boost";
import { call, put, takeEvery } from "redux-saga/effects";
import client from "../../../client";
import { SessionTypes } from "../../../components/Session/store/sessionTypes";
import { setUserData } from "./currentUserActions";

export const CURRENT_USER_FRAGMENT = gql`
    fragment CurrentUser  on Session {
        user {
            id
            email
            name
            surname
            administrationRole
        }
    }
`;

const CURRENT_USER_QUERY = gql`
    query CurrentUser {
        session {
            ...CurrentUser
        }
    }
    ${CURRENT_USER_FRAGMENT}
`;

export default function* currentUserSaga() {
    yield takeEvery(SessionTypes.START, loadUserData);
    yield takeEvery(SessionTypes.CLEAR, removeUserData);
}

function* loadUserData() {
    const {data: {session: {user}}} = yield call(client.query, {
        query: CURRENT_USER_QUERY,
    });

    yield put(setUserData({
        id: user.id,
        email: user.email,
        name: user.name,
        surname: user.surname,
        userRole: user.administrationRole
    }));
}

function* removeUserData() {
    yield put(setUserData(
        null
    ));
}
