import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import React, { FC, ReactElement, ReactNode } from "react";
import { useSelector } from "react-redux";
import BreadCrumb from "../../components/BreadCumb/BreadCrumb";
import { getCurrentPathname } from "../../store/modules/router/routerSelectors";
import DefaultLayout, { DefaultLayoutProps } from "../DefaultLayout/DefaultLayout";

interface MainLayoutProps {
    title: string;
    breadCrumbsRoutes?: Route[];
    description?: ReactNode;
    extra?: ReactElement;
}

const PageLayout: FC<MainLayoutProps> = (props) => {
    const currentPath = useSelector(getCurrentPathname);

    const pageHeader: DefaultLayoutProps["pageHeader"] = {
        title: props.title,
        breadcrumb: {
            itemRender: BreadCrumb,
            routes: [
                {breadcrumbName: "Strona główna", path: '/'},
                ...(props.breadCrumbsRoutes || []),
                {path: currentPath, breadcrumbName: props.title},
            ],
        },
        children: props.description,
        extra: props.extra,
    };

    return (
        <DefaultLayout
            title={props.title}
            pageHeader={pageHeader}
        >
            {props.children}
        </DefaultLayout>
    );
};

export default PageLayout;
