import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import React, { forwardRef, RefForwardingComponent } from "react";

export const ROLE_MAP: ({ [key: string]: string }) = {
    ROLE_ADMIN: "Dyrektor / Administrator - psycholog",
    ROLE_ADMIN_NOT_PSYCHOLOGIST: "Dyrektor / Administrator - niepsycholog",
    ROLE_SPECIALIST: "Specjalista",
    ROLE_EXTERNAL_SPECIALIST: "Specjalista zewnętrzny",
}

interface TenantRoleSelectProps extends SelectProps<string>{
    filterRoles?: string[]
}

const TenantRoleSelect: RefForwardingComponent<Select<string>, TenantRoleSelectProps> = (props, ref) => {
    return (
        <Select
            ref={ref}
            placeholder="Rola"
            style={{ width: "250px", marginRight: "16px" }}
            {...props}
        >
            {
                Object.entries(ROLE_MAP)
                    .filter(item => props.filterRoles ? props.filterRoles.includes(item[0]) : true)
                    .map(([value, label]) => (
                  <Select.Option key={value} value={value}>
                      { label }
                  </Select.Option>
                ))
            }
        </Select>
    );
};

export default forwardRef(TenantRoleSelect)
