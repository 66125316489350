import { Avatar } from "antd";
import { AvatarProps } from "antd/lib/avatar";
import React, { CSSProperties, FC, useMemo } from "react";
import { isDarkColor, uuidToColor } from "../../utils/colors";

interface UserAvatarProps {
    userId: string;
    name: string;
    surname: string;
    size?: AvatarProps["size"];
}

const UserAvatar: FC<UserAvatarProps> = ({userId, name, surname, size}) => {
    const style = useMemo<CSSProperties>(() => {
        const background = uuidToColor(userId);
        const color = isDarkColor(background) ? 'white' : 'black';

        return {
            color,
            background: `#${background}`,
        };
    }, [userId]);

    return (
        <Avatar
            style={style}
            size={size}
            alt={`${surname} ${name}`}
        >
            {surname.charAt(0)}
            {name.charAt(0)}
        </Avatar>
    );
};

export default UserAvatar;
