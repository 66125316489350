import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient} from 'apollo-boost';
import {ErrorResponse, onError} from "apollo-link-error";
import clientFetch from "./fetch";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from "apollo-boost";
import {store} from "./index";
import {clearSession} from "./components/Session/store/sessionActions";
import { ServerError } from 'apollo-link-http-common';

const cache = new InMemoryCache();

const uploadLink = (createUploadLink({
    uri: '/graphql/',
    fetch: clientFetch,
}) as unknown) as ApolloLink;

const errorLink = onError((error: ErrorResponse) => {
    if (error.networkError
        && (error.networkError as ServerError).statusCode
        && (error.networkError as ServerError).statusCode === 401
    ) {
        store.dispatch(clearSession())
        window.location.href = window.location.origin + "/login"
    }

    if (error.networkError || !error.graphQLErrors) {
        throw error;
    }

    if (1 === error.graphQLErrors.length && !error.graphQLErrors[0].path) {
        throw error;
    }
});

const link = ApolloLink.from([
    errorLink,
    uploadLink,
]);

const client = new ApolloClient({
    cache,
    link,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "cache-and-network",
            notifyOnNetworkStatusChange: true,
        },
    },
});

export default client;
