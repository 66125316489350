import { WrappedFormUtils } from "antd/lib/form/Form";

export default (form: WrappedFormUtils) => {
    const fieldsError = form.getFieldsError();
    for (const fieldName in fieldsError) {
        if (fieldsError.hasOwnProperty(fieldName)) {
            form.setFieldsValue({[fieldName]: form.getFieldValue(fieldName)});
        }
    }
};
