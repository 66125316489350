import { ApolloProvider } from '@apollo/react-hooks';
import React, { ComponentType, FC } from "react";
import ApolloClient from 'apollo-client';

export default function <Props>(client: ApolloClient<any>) {
    return (WrappedComponent: ComponentType<Props>) => {
        const Component: FC<Props> = (props) => (
            <ApolloProvider client={client}>
                <WrappedComponent {...props}/>
            </ApolloProvider>
        );

        Component.displayName = `withApollo(${WrappedComponent.displayName || WrappedComponent.name})`;

        return Component;
    };
}
