import { WrappedFormUtils } from "antd/lib/form/Form";
import { ErrorResponse } from "apollo-link-error";
import translateViolation, { ConstraintViolation } from "./constraintViolation";

const processResponseException = (
    form: WrappedFormUtils,
    error: ErrorResponse,
    fieldMap : Record<string, string> = {},
) => {
    if (error.networkError || !error.graphQLErrors) {
        throw error;
    }

    const violations = error.graphQLErrors
        .filter(({extensions}) => extensions && extensions.category === 'validator')
        .map((error: any): Record<string, ConstraintViolation | undefined> => error.state)
        .reduce((violations, group) => ({
            ...group,
            ...violations,
        }), {});

    for (const fieldName in violations) {
        if (violations.hasOwnProperty(fieldName)) {
            const violation = translateViolation(violations[fieldName] as ConstraintViolation);
            const mappedField = mapFieldName(fieldName, fieldMap);
            const errors = [new Error(violation.message)];
            const value = form.getFieldValue(mappedField);
            form.setFields({[mappedField]: {errors, value}});
        }
    }
};

const mapFieldName = (name: string, map: Record<string, string>): string => {
    if (map[name]) {
        return map[name];
    }

    const parts = name.split(".");

    if (1 === parts.length) {
        return name;
    }

    const right = parts.pop();

    return `${mapFieldName(parts.join("."), map)}.${right}`;
};

export default processResponseException;
