export const uuidToColor = (uuid: string, dark: boolean | null = null): string => {
    const color = `${uuid.slice(0, 6)}`;

    return null == dark || isDarkColor(color) === dark ? color : inverseColor(color);
};

export const isDarkColor = (hexColor: string) => (hexColor.match(/.{2}/g) as string[])
    .map((n) => parseInt(n, 16))
    .reduce((s, n) => s + n, 0) <= 256 * 3 / 2;

export const inverseColor = (hexColor: string) => (hexColor.match(/.{2}/g) as string[])
    .map((n) => (255 - parseInt(n, 16)).toString(16))
    .map((n) => '0'.repeat(2- n.length)+n)
    .join('');
