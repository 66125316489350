import React, { lazy, Suspense } from 'react';
import { hot } from "react-hot-loader";
import { Route, Switch } from "react-router-dom";
import UpdateUserPage from "../UpdateUser/UpdateUserPage";
import Loader from "../Utils/Loader/Loader";
import { AuthRoute, SecureRoute } from "./components/Route";

const DashboardPage = lazy(() => import(`../Dashboard/DashboardPage`));
const LoginFormPage = lazy(() => import("../LoginForm/LoginFormPage"));
const PageNotFound = lazy(() => import("../NotFound/NotFoundPage"));
const TenantsListPage = lazy(() => import("../TenantsList/TenantsListPage"));
const CreateTenantPage = lazy(() => import("../CreateTenant/CreateTenantPage"));
const UpdateTenantPage = lazy(() => import("../UpdateTenant/UpdateTenantPage"));
const UsersListPage = lazy(() => import("../UsersList/UserListPage"));
const AssignUserPage = lazy(() => import("../AssignUser/AssignUserPage"));
const ToolsListPage = lazy(() => import("../ToolsList/ToolsListPage"));
const ReportListPage = lazy(() => import("../ReportList/ReportListPage"));
const PostdiagnosticMaterialsPage = lazy(() => import("../PostdiagnosticMaterials/PostdiagnosticMaterialsPage"));
const PostdiagnosticMaterialRegistration = lazy(() => import("../PostdiagnosticMaterials/PostdiagnosticMaterialRegistration"));
const RegisterUserPage = lazy(() => import("../RegisterUser/RegisterUserPage"));
const ActivateUserPage = lazy(() => import("../ActivateUserPage/ActivateUserPage"));
const SendResetPasswordEmailForm = lazy(() => import("../ResetPassword/SendResetPasswordEmailForm"));
const ResetPasswordForm = lazy(() => import("../ResetPassword/ResetPasswordForm"));
const SystemStatusPage = lazy(() => import("../SystemStatus/SystemStatusPage"));
const SchoolsListPage = lazy(() => import("../Schools/SchoolsList/SchoolsListPage"));
const SchoolsRegistrationFormPage = lazy(() => import("../Schools/SchoolRegistrationForm/SchoolsRegistrationFormPage"));
const SchoolEditPage = lazy(() => import("../Schools/SchoolEditPage/SchoolEditFormPage"));
const ToolDetailsFormPage = lazy(() => import("../ToolDetailsForm/ToolDetailsFormPage"));
const ToolCardPage = lazy(() => import("../ToolCard/ToolCardPage"));
const RegisterToolStandardsGroup = lazy(() => import("../ToolStandards/RegisterToolStandardsGroup/RegisterToolStandardsGroup"));
const UpdateToolStandardsGroup = lazy(() => import("../ToolStandards/UpdateToolStandardsGroup/UpdateToolStandardsGroup"));
const UpdateSurveyPage = lazy(() => import("../Surveys/UpdateSurvey/UpdateSurveyPage"));
const PreviewSurveyPage = lazy(() => import("../Surveys/PreviewSurvey/PreviewSurveyPage"));
const SurveyListPage = lazy(() => import("../Surveys/SurveyList/SurveyListPage"));
const RegisterSurveyPage = lazy(() => import("../Surveys/RegisterSurvey/RegisterSurveyPage"));
const ManualsAndInstructionsPage = lazy(() => import ('../ManualsAndInstructions/ManualsAndInstructionsPage'))
const ManualsAndInstructionsRegistrationPage = lazy(() => import ('../ManualsAndInstructions/ManualsAndInstructionsRegistration'))
const RegisterTenantWithoutAuthPage = lazy(() => import ('../RegisterTenantWithoutAuth/RegisterTenantWithoutAuthPage'))
const RegisterTenantWithoutAuthAdminPage = lazy(() => import ('../RegisterTenantWithoutAuth/RegisterTenantWithoutAuthAdminPage'))

const Router: React.FC = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <SecureRoute path="/" exact component={DashboardPage}/>
                <SecureRoute path="/tenants/list/:page(\d+)?" exact component={TenantsListPage} />
                <SecureRoute path="/tenants/create" exact component={CreateTenantPage} />
                <SecureRoute path="/tenants/:tenantId/update" exact component={UpdateTenantPage} />
                <SecureRoute path="/tenants/:tenantId/register" exact component={RegisterTenantWithoutAuthAdminPage}/>
                <SecureRoute path="/tenants/:tenantId/schools" exact component={SchoolsListPage} />
                <SecureRoute path="/tenants/:tenantId/schools/register" exact component={SchoolsRegistrationFormPage} />
                <SecureRoute path="/tenants/:tenantId/schools/:schoolId/edit" exact component={SchoolEditPage} />
                <SecureRoute path="/users/list/:page(\d+)?" exact component={UsersListPage} />
                <SecureRoute path="/users/:userId/assign" exact component={AssignUserPage} />
                <SecureRoute path="/tools/list/:page(\d+)?" exact component={ToolsListPage} />
                <SecureRoute path="/tools/create" exact component={ToolDetailsFormPage} />
                <SecureRoute path="/tools/:toolId/card" exact component={ToolCardPage} />
                <SecureRoute path="/tools/:toolId/create-standards-group" exact component={RegisterToolStandardsGroup} />
                <SecureRoute path="/tools/:toolId/update-standards-group/:standardGroupId/:preview?" exact component={UpdateToolStandardsGroup} />
                <SecureRoute path="/reports" exact component={ReportListPage} />
                <SecureRoute path="/materials" exact component={PostdiagnosticMaterialsPage} />
                <SecureRoute path="/materials/create" exact component={PostdiagnosticMaterialRegistration} />
                <SecureRoute path="/users/register" exact component={RegisterUserPage} />
                <SecureRoute path="/users/:userId/update" exact component={UpdateUserPage} />
                <SecureRoute path="/users/:userId/activate" exact component={ActivateUserPage} />
                <SecureRoute path="/surveys/list" exact component={SurveyListPage} />
                <SecureRoute path="/survey/register" exact component={RegisterSurveyPage} />
                <SecureRoute path="/surveys/:surveyId/update" exact component={UpdateSurveyPage} />
                <SecureRoute path="/surveys/:surveyId/preview" exact component={PreviewSurveyPage} />
                <SecureRoute path="/status" exact component={SystemStatusPage} />
                <SecureRoute path="/manuals" exact component={ManualsAndInstructionsPage}/>
                <SecureRoute path="/manuals/create" exact component={ManualsAndInstructionsRegistrationPage}/>
                <AuthRoute path="/login" exact component={LoginFormPage}/>
                <Route path="/register-tenant/:tenantId?" exact component={RegisterTenantWithoutAuthPage}/>
                <Route path="/password/send-email" exact component={SendResetPasswordEmailForm}/>
                <Route path="/password/:token/reset" exact component={ResetPasswordForm}/>
                <Route component={PageNotFound}/>
            </Switch>
        </Suspense>
    );
};

export default hot(module)(Router);
