import React, { FC } from "react";
import oso from "./oso.png";

export const Oso: FC<JSX.IntrinsicElements["img"]> = (props) => (
    <img
        src={oso}
        alt="Logo obszaru osobowości"
        title="Obszar osobowości"
        height={props.height || props.width}
        width={props.width || props.height}
        {...props}
    />
);

export default Oso;
