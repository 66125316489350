import { Avatar, Icon, Menu } from "antd";
import { default as React, FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSession } from "../../../../components/Session/store/sessionActions";
import UserAvatar from "../../../../components/UserAvatar/UserAvatar";
import Loader from "../../../../components/Utils/Loader/Loader";
import { CurrentUser } from "../../../../store/modules/currentUser/currentUserReducer";
import { getUserData } from "../../../../store/modules/currentUser/currentUserSelectors";
import styles from "./userMenu.module.less";
import {ROLE_MAP} from "../../../../components/TenantRole/TenantRoleSelect";

const UserMenu: FC = () => {
    const dispatch = useDispatch();
    const logout = useCallback(() => dispatch(clearSession()), [dispatch]);
    const currentUser = useSelector(getUserData) as CurrentUser;

    const avatar = currentUser ? (
        <UserAvatar
            userId={currentUser.id}
            name={currentUser.name}
            surname={currentUser.surname}
        />
    ) : <Avatar />;

    return (
        <Menu mode="horizontal" selectedKeys={[]} className={styles.menu}>
            <Menu.SubMenu title={avatar}>
                {currentUser
                    ? <Menu.Item disabled>Witaj, {currentUser!.surname} {currentUser!.name} - {ROLE_MAP[currentUser.userRole]} &lt;{currentUser!.email}</Menu.Item>
                    : <Loader/>
                }
                <Menu.Divider />
                <Menu.Item onClick={logout}>
                    <Icon type="logout"/>
                    <span>Wyloguj</span>
                </Menu.Item>
            </Menu.SubMenu>
        </Menu>
    );
};

export default UserMenu;
