import jwtDecode from "jwt-decode";

export const decodeJsonWebToken = (token: string) => {
    const {exp, username} = jwtDecode(token);
    const expirationDate = timestampToDate(exp);

    return {token, expirationDate, username}
}

const timestampToDate = (timestamp: number) => new Date(timestamp * 1000);
