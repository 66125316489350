import React, { FC } from "react";
import logo from "./logo.png";

export const Logo: FC<JSX.IntrinsicElements["img"]> = (props) => (
    <img
        src={logo}
        alt="Logo obszaru emocjonalo-społecznego"
        title="Obszar emocjonalo-społeczny"
        height={props.height || props.width}
        width={props.width || props.height}
        {...props}
    />
);

export default Logo;
