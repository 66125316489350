import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({path, breadcrumbName}: {path: string, breadcrumbName: string}) => (
    <Link to={path}>
        {breadcrumbName}
    </Link>
);

export default BreadCrumb;
