import { VersionTypes } from "./versionTypes";

export const checkUpdate = (
    force: boolean = false, //I'm sure there is a new version
) => ({
    type: VersionTypes.CHECK_UPDATE,
    payload: {force},
});

export const updateAvailable = () => ({
    type: VersionTypes.UPDATE_AVAILABLE,
});

export const updateVersion = () => ({
    type: VersionTypes.UPDATE,
});
