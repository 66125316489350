import { useCallback, useEffect, useState } from "react";

const useInterval = (callback: () => void, time: number) => {
    const [int, setInt] = useState<NodeJS.Timeout | null>();

    const start = useCallback(() => {
        if (null == int) {
            setInt(setInterval(() => callback(), time));
        }
    }, [setInt, int, time, callback]);

    const stop = useCallback(() => {
        if (null != int) {
            clearInterval(int);
            setInt(null);
        }
    }, [setInt, int]);

    useEffect(() => {
        start();

        return () => {
            stop();
        };
    }, [start, stop]);

    return {start, stop};
};

export default useInterval;
