import { useMutation, useQuery } from "@apollo/react-hooks";
import {Button, Card, Col, Form, Icon, Input, Popconfirm, Row} from "antd";
import { FormComponentProps } from "antd/lib/form";
import { gql } from "apollo-boost";
import { push } from "connected-react-router";
import React, { FC, FormEvent, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "../../layouts/PageLayout/PageLayout";
import { getUserData } from "../../store/modules/currentUser/currentUserSelectors";
import clearFormErrors from "../Form/clearFormErrors";
import processResponseException from "../Form/processResponseException";
import { clearSession } from "../Session/store/sessionActions";
import { UserListItem } from "../UsersList/model/UserListItem";
import Loader from "../Utils/Loader/Loader";
import {ErrorResponse} from "apollo-link-error";

type UpdateUserPageProps = FormComponentProps & {
    userId: string;
};

const GET_USER_BY_ID = gql`
    query getUserById($userId: String!) {
        userById(userId: $userId) {
            id,
            name,
            surname,
            email,
            phoneNumber
        }
    }
`;

const UPDATE_USER = gql`
    mutation updateUser($userId: String!, $name: String!, $surname: String!, $email: String!, $phoneNumber: String) {
        updateUser(
            userId: $userId,
            name: $name,
            surname: $surname,
            email: $email,
            phoneNumber: $phoneNumber,
        ) {
            id
            name
            surname
            email
            phoneNumber
        }
    }
`;

const UpdateUserPage: FC<UpdateUserPageProps> = ({form, userId}) => {
    const [updateUser] = useMutation(UPDATE_USER);
    const dispatch = useDispatch();
    const logout = useCallback(() => dispatch(clearSession()), [dispatch]);
    const currentUserEmail = useSelector(getUserData)?.email;
    const [selfEditing] = useState(currentUserEmail === form.getFieldValue("email"));
    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        saveData(() => dispatch(push("/users/list")));
    };

    const saveData = (onSuccessCallback: () => void) => {
        clearFormErrors(form);
        form.validateFields(async (e) => {
            if (e) return;
            const userData = form.getFieldsValue();

            try {
                await updateUser({
                    variables: {
                        userId,
                        name: userData.name,
                        surname: userData.surname,
                        email: userData.email,
                        phoneNumber: userData.phoneNumber ? userData.phoneNumber : undefined,
                    }
                });

                onSuccessCallback();
            } catch (ex) {
                processResponseException(form, ex as ErrorResponse);
            }
        });
    };

    return (
        <PageLayout
            title="Edycja użytkownika"
            breadCrumbsRoutes={[{path: "/users/list", breadcrumbName: "Użytkownicy"}]}
        >
            <Card>
                <Form onSubmit={handleSubmit} labelAlign="left">
                    <fieldset>
                        <legend>Dane osobowe</legend>
                        <Row gutter={24}>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Imię">
                                    {form.getFieldDecorator("name", {
                                        rules: [
                                            {required: true, message: "Imię jest wymagane."},
                                        ]
                                    })(
                                        <Input placeholder="Imię"/>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Nazwisko">
                                    {form.getFieldDecorator("surname", {
                                        rules: [
                                            {required: true, message: "Nazwisko jest wymagane."},
                                        ]
                                    })(
                                        <Input placeholder="Nazwisko"/>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Adres e-mail">
                                    {form.getFieldDecorator("email", {
                                        rules: [
                                            {required: true, message: "Email jest wymagany"},
                                            {type: "email", message: "Niepoprawny adres e-mail"}
                                            ],
                                    })(
                                        <Input placeholder="Adres e-mail" type="email"/>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Numer telefonu">
                                    {form.getFieldDecorator("phoneNumber")(
                                        <Input placeholder="Numer telefonu"/>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                    </fieldset>
                    {selfEditing && currentUserEmail !== form.getFieldValue("email") ?
                        <Popconfirm
                            title="Zmieniłeś adres e-mail przypisany do twojego konta. W przypadku potwierdzenia tej operacji zostaniesz wylogowany."
                            onConfirm={() => saveData(() => logout())}
                            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                            okText="Potwierdzam"
                            cancelText="Anuluj"
                        >
                            <Button type="primary" icon="save" style={{float: "right"}}>
                                Zapisz dane użytkownika
                            </Button>
                        </Popconfirm>
                        :
                        <Button htmlType="submit"  type="primary" icon="save" style={{float: "right"}}>
                            Zapisz dane użytkownika
                        </Button>
                    }
                </Form>
            </Card>
        </PageLayout>
    );
};

type PropsWithUserById = FormComponentProps & {
    userById: UserListItem
}

const EditForm = Form.create({
    mapPropsToFields(props: PropsWithUserById) {
        let data = props.userById;
        return {
            email: Form.createFormField({
                value: data.email
            }),
            name: Form.createFormField({
                value: data.name
            }),
            surname: Form.createFormField({
                value: data.surname
            }),
            phoneNumber: Form.createFormField({
                value: data.phoneNumber
            }),
        }
    },
})(UpdateUserPage);

type PropsWithUserIdInUrl = Object & {
    match: {
        params: {
            userId: string
        }
    }
}

const Container = (viewData: PropsWithUserIdInUrl) => {
    const userId = viewData.match.params.userId;

    const { data, loading } = useQuery(GET_USER_BY_ID, {variables: {userId}});

    if (loading) return <Loader/>;

    return <EditForm {...data} userId={userId}/>
};

export default Container;
