import { Result } from "antd";
import React, { ComponentType } from "react";
import supportedBrowsers from "../supportedBrowsers";

export default function <Props>(WrappedComponent: ComponentType<Props>) {
    const Component: ComponentType<Props> = (props) => {
        if (!navigator.userAgent || !navigator.userAgent.match(supportedBrowsers)) {
            return (
                <Result
                    status="warning"
                    title="Niewspierana przeglądarka"
                    subTitle="Używasz przestarzalej przeglądarki internetowej, która nie jest wspierana przez tą aplikację. Prosimy o skorzystanie z innej przeglądarki lub użycie innego dostępnego urządzenia."
                />
            );
        }

        return <WrappedComponent {...props} />;
    };

    Component.displayName = `withSupportedBrowser(${WrappedComponent.displayName || WrappedComponent.name})`;

    return Component;
}
