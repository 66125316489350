import { Button, Modal, Tooltip, Typography } from "antd";
import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux"
import useForceRerender from "../../hooks/useForceRerender";
import useInterval from "../../hooks/useInterval";
import { clearSession, sessionRefresh } from "./store/sessionActions";
import { getSessionExpirationDate } from "./store/sessionSelectors";

const { Text } = Typography;

const SessionManager: FC = () => {
    const expirationDate = useSelector(getSessionExpirationDate);
    const secondsLeft = expirationDate ? Math.floor((+expirationDate - +new Date())/1000) : null;
    const rerender = useForceRerender();
    useInterval(rerender, 1000);
    const dispatch = useDispatch();
    const refreshSession = useCallback(() => dispatch(sessionRefresh()), [dispatch]);
    const logout = useCallback(() => dispatch(clearSession()), [dispatch]);

    if (null == secondsLeft) {
        return null;
    }

    if(secondsLeft < 1) {
        logout();
    }

    const minutes = Math.floor(secondsLeft / 60);
    const seconds = secondsLeft % 60;

    const button = (
        <Tooltip title="Zresetuj czas sesji">
            <Button
                type="link"
                onClick={refreshSession}
                style={{padding: "0 5px"}}
                icon="reload"
            />
        </Tooltip>
    );

    const timeLeft = (
        <Text
            type={180 > secondsLeft ? "danger" : undefined}
            style={{cursor: "default"}}
        >
            {zeroPadded(minutes)}:{zeroPadded(seconds)}
        </Text>
    );

    const text = (
        <Tooltip title="Czas do końca sesji">
            {timeLeft}
        </Tooltip>
    );

    const modal = (
        <Modal
            visible={30 > secondsLeft}
            title="Czas sesji dobiega końca"
            okText="Kontunuj korzystanie z aplikacji"
            onOk={refreshSession}
            okButtonProps={{icon: "reload"}}
            cancelText="Wyloguj"
            onCancel={logout}
            cancelButtonProps={{icon: "logout"}}
            closable={false}
            maskClosable={false}
            destroyOnClose={true}
        >
            <p>Z powodu braku aktywności zostaniesz za chwilę wylogowany z systemu.</p>
            <p>Czas do końca sesji: {timeLeft}</p>
        </Modal>
    );

    return (
        <div style={{float: "left"}}>
            {text}
            {modal}
            {button}
        </div>
    )
}

const zeroPadded = (n: number) => n < 10 ? `0${n}` : `${n}`;

export default SessionManager
