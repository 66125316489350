const sessionKey = "token";

export default new class {
    clear(): void {
        localStorage.removeItem(sessionKey)
    }
    write(token: string): void {
        localStorage.setItem(sessionKey, token)
    }
    read(): string | null {
        const token = localStorage.getItem(sessionKey)

        return null == token ? null : token;
    }
}();
