import { Reducer } from "redux";
import { VersionTypes } from "./versionTypes";

interface VersionState {
    updateAvailable: boolean;
    updating: boolean;
}

const initialState: VersionState = {
    updateAvailable: false,
    updating: false,
}

const versionReducer: Reducer<VersionState> = (state = initialState, action) => {
    switch (action.type) {
        case VersionTypes.UPDATE_AVAILABLE:
            return {
                ...state,
                updateAvailable: true
            };
        case VersionTypes.UPDATE:
            return {
                ...state,
                updating: true,
            }
    }

    return state;
}

export default versionReducer;
