import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router";
import { hasSession } from "../../Session/store/sessionSelectors";

export const createConditionalRoute = (condition: () => boolean, redirectTo: () => string): FC<RouteProps> => {
    return (props) => {
        if (condition()) {
            return (
                <Route
                    {...props}
                    component={undefined}
                    render={() => <Redirect to={redirectTo()} />}
                />
            );
        }

        return <Route {...props} />;
    };
};

export const AuthRoute = createConditionalRoute(
    () => useSelector(hasSession),
    () => {
        const locationSearch = window.location.search
        const refParam = getQueryString(locationSearch, 'ref')
        return refParam || "/"
    }
);

export const SecureRoute = createConditionalRoute(
    () => !useSelector(hasSession),
    () => `/login?ref=${encodeURIComponent(window.location.pathname)}`
);

export function getQueryString(queryParams: string, param: string) {
    const params = new Proxy(new URLSearchParams(queryParams), {
        get: (searchParams, prop) => searchParams.get(prop as string),
    });

    // @ts-ignore
    return params[param] || ''
}
