import { SessionTypes } from "./sessionTypes";

export const initSession = (token: string) => ({
    type: SessionTypes.INIT,
    payload: {token},
});
export type InitSessionAction = ReturnType<typeof initSession>;

export const sessionStart = (
    payload: {
        token: string,
        expirationDate: Date,
        username: string,
    }
) => ({
    type: SessionTypes.START,
    payload,
});

export type SessionStartAction = ReturnType<typeof sessionStart>;

export const sessionRefresh = () => ({
    type: SessionTypes.REFRESH,
});

export const clearSession = () => ({
    type: SessionTypes.CLEAR,
});
