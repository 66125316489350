import { fork } from "redux-saga/effects";
import sessionSaga from "../components/Session/store/sessionSaga";
import currentUserSaga from "./modules/currentUser/currentUserSaga";
import versionSaga from "./modules/version/versionSaga";

export default function* () {
    yield fork(sessionSaga);
    yield fork(currentUserSaga);
    yield fork(versionSaga);
}
