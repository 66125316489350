import { Icon, Menu } from "antd";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import styles from './mainMenu.module.less';
import { useSelector } from "react-redux";
import {getCurrentPathname} from "../../../../store/modules/router/routerSelectors";

const MainMenu: FC = () => {
    const currentUrl = useSelector(getCurrentPathname);

    return (
        <Menu
            defaultSelectedKeys={['/']}
            selectedKeys={[currentUrl]}
            theme="dark"
            className={styles.mainMenu}
        >
            <Menu.Item key="/">
                <Link to="/">
                    <Icon type="home" />
                    <span>Strona główna</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/tenants/list">
                <Link to="/tenants/list">
                    <Icon type="fork"/>
                    <span>Placówki</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/users/list">
                <Link to="/users/list">
                    <Icon type="user"/>
                    <span>Użytkownicy</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/tools/list">
                <Link to="/tools/list">
                    <Icon type="key"/>
                    <span>Narzędzia</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/surveys/list">
                <Link to="/surveys/list">
                    <Icon type="profile"/>
                    <span>Ankiety</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/manuals">
                <Link to="/manuals">
                    <Icon type="profile"/>
                    <span>Podręczniki i instrukcje</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/materials">
                <Link to="/materials">
                    <Icon type="switcher"/>
                    <span>Materiały postdiagnostyczne</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/reports">
                <Link to="/reports">
                    <Icon type="file"/>
                    <span>Raporty</span>
                </Link>
            </Menu.Item>
        </Menu>
    );
};

export default MainMenu;
