import { Drawer, Layout } from "antd";
import { CSSProperties, default as React, FC, useCallback } from "react";
import useMedia from "use-media";
import Logo from "../../../../components/Logo/Logo";
import Oso from "../../../../components/Logo/Oso";
import MainMenu from "../MainMenu/MainMenu";
import styles from "./sideMenu.module.less";

const {Sider} = Layout;

interface SideMenuProps {
    collapsed: boolean;
    setCollapsed(collapsed: boolean): void;
}

const SideMenu: FC<SideMenuProps> = ({collapsed, setCollapsed}) => {
    const isMobile = useMedia({maxWidth: 768}, false);
    const collapse = useCallback(() => setCollapsed(true), [setCollapsed]);
    const onBreakpoint = useCallback((broken: boolean) => {
        if (broken && !collapsed) collapse();
    }, [collapsed, collapse]);

    const menu = (
        <div className={styles.menu}>
            <h1 className={`${styles.logo} ${collapsed ? styles.collapsed : ""}`}>
                <Logo height={48} />
                <Oso height={48} />
                Administracja
            </h1>
            <MainMenu/>
        </div>
    );

    return isMobile ? (
        <Drawer
            visible={!collapsed}
            onClose={collapse}
            bodyStyle={drawerBodyStyle}
            placement="left"
            closable={false}
        >
            {menu}
        </Drawer>
    ) : (
        <Sider
            className={styles.sider}
            collapsed={collapsed}
            breakpoint="md"
            width={256}
            onBreakpoint={onBreakpoint}
        >
            {menu}
        </Sider>
    );
};

const drawerBodyStyle: CSSProperties = {
    padding: 0,
};

export default SideMenu;
